$desktop: 1440px;
$laptop: 768px;

@mixin responsive($breakpoints...) {
  @if index($breakpoints, desktop) {
    @media (min-width: $desktop) { @content ; }
  }

  @if index($breakpoints, laptop) {
    @media (min-width: $laptop) and (max-width: calc(#{$desktop} - 1px)) { @content ; }
  }

  @if index($breakpoints, mobile) {
    @media (max-width: calc(#{$laptop} - 1px))  { @content ; }
  }
}
