@import 'src/css/responsive.scss';
@import 'src/css/colors.scss';

@include responsive(mobile) {
  .PaymentRow {
    align-items: center;
    display: flex;
    height: 64px;
    justify-content: space-between;
    padding: 0 16px;

    .payment-direction {
      align-items: center;
      display: flex;
      font-size: 16px;

      .payer-name {
        color: $codemode-red;
        margin-right: 4px;
      }

      .payee-name {
        color: $codemode-green;
        margin-left: 4px;
      }
    }

    .payment-details {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .player-balance {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .balance-amount {
          font-size: 18px;
        }
      }

      .angle-icon {
        font-size: 20px;
      }
    }
  }
}
