@import 'src/css/responsive.scss';
@import 'src/css/colors.scss';

@include responsive(mobile) {
  .GameRow {
    align-items: center;
    display: flex;
    height: 64px;
    justify-content: space-between;
    padding: 0 16px;

    .game-name {
      font-size: 16px;

      &.blue {
        color: $codemode-blue;
      }
    }

    .game-details {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .game-players {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 12px;

        .players-label {
          font-size: 12px;
          margin-bottom: 4px;
        }

        .players-amount {
          font-size: 18px;
        }
      }

      .angle-icon {
        font-size: 20px;
      }
    }
  }
}
