@import 'src/css/responsive.scss';
@import 'src/css/colors.scss';

@include responsive(mobile) {
  .NewPlayerRow {
    align-items: center;
    display: flex;
    height: 64px;
    justify-content: space-between;
    padding: 0 16px;

    .player-name {
      border: 1px solid #808080;
      border-radius: 4px;
      height: 36px;
      flex: 1;
      font-family: 'Roboto Condensed';
      font-size: 16px;
      outline: 0;
      padding: 0 6px;

      &:focus {
        border: 1px solid $codemode-blue;
      }
    }

    .player-details {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      width: 48px;

      .check-icon {
        color: $codemode-green;
        font-size: 20px;
      }
    }
  }
}
