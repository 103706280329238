@import 'src/css/responsive.scss';
@import 'src/css/colors.scss';

@include responsive(mobile) {
  .ResultsRow {
    align-items: center;
    display: flex;
    height: 64px;
    justify-content: space-between;
    padding: 0 16px;

    .player-name {
      font-size: 16px;
    }

    .player-details {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .player-balance {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &.green {
          color: $codemode-green;
        }

        &.red {
          color: $codemode-red;
        }

        .balance-label {
          font-size: 12px;
          margin-bottom: 4px;
        }

        .balance-amount {
          font-size: 18px;
        }
      }

      .angle-icon {
        font-size: 20px;
      }
    }
  }
}
