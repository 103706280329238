@import 'src/css/responsive.scss';
@import 'src/css/colors.scss';

@include responsive(mobile) {
  .PaymentsPage {
    align-items: center;
    display: flex;
    flex-direction: column;

    .payments-container {
      margin-top: 96px;
      width: 100%;

      .PaymentRow {
        border-bottom: 1px solid #E0E0E0;

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}
