@import 'src/css/responsive.scss';
@import 'src/css/colors.scss';

@include responsive(mobile) {
  .Footer {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    padding: 16px;
    width: 100%;

    .footer-message {
      margin-bottom: 12px;
    }

    .contact-button {
      align-items: center;
      background: $codemode-blue;
      border-radius: 8px;
      color: white;
      display: flex;
      font-size: 18px;
      font-weight: bold;
      height: 48px;
      justify-content: center;
      padding: 0 32px;
    }
  }
}
