@import 'src/css/responsive.scss';

@include responsive(mobile) {
  .App {
    height: 100vh;
    width: 100vw;

    &.desktop {
      display: none;
    }
  }
}

@include responsive(laptop, desktop) {
  .App {
    height: 100vh;
    width: 100vw;

    &.mobile {
      display: none;
    }
  }
}
