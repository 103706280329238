@import 'src/css/responsive.scss';
@import 'src/css/colors.scss';

@include responsive(mobile) {
  .ResultsNavbar {
    align-items: center;
    background: $codemode-blue;
    color: white;
    display: flex;
    height: 80px;
    justify-content: space-between;
    padding: 0 16px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;

    .page-title {
      font-size: 20px;
      font-weight: 500;
    }

    .nav-control {
      align-items: center;
      display: flex;
      width: 96px;

      &.left {
        justify-content: flex-start;

        .angle-icon {
          font-size: 18px;
        }

        .back-label {
          margin-left: 6px;
        }
      }

      &.right {
        justify-content: flex-end;

        .payments-label {

        }
      }
    }
  }
}
