@import 'src/css/responsive.scss';
@import 'src/css/colors.scss';

@include responsive(mobile) {
  .BuyinRow {
    align-items: center;
    display: flex;
    height: 64px;
    justify-content: space-between;
    padding: 0 16px;

    .buyin-time {
      font-size: 16px;
    }

    .buyin-details {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .buyin-amount {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 12px;

        .buyin-label {
          font-size: 12px;
          margin-bottom: 4px;
        }

        .buyin-amount {
          font-size: 18px;
        }
      }

      .times-icon {
        color: $codemode-red;
        font-size: 20px;
      }
    }
  }
}
