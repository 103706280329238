@import 'src/css/responsive.scss';
@import 'src/css/colors.scss';

@include responsive(mobile) {
  .ResultsPage {
    align-items: center;
    display: flex;
    flex-direction: column;

    .game-summary {
      align-items: center;
      border-radius: 12px;
      box-shadow: 2px 2px 8px #808080;
      color: black;
      display: flex;
      flex-direction: column;
      margin-top: 96px;
      padding: 24px;
      width: calc(100% - 32px);

      .game-title {
        font-size: 24px;
      }

      .game-stats {
        align-items: flex-end;
        display: flex;
        justify-content: space-evenly;
        margin-top: 16px;
        width: 100%;

        .stats-group {
          align-items: center;
          display: flex;
          flex-direction: column;

          .group-amount {
            font-size: 24px;
            font-weight: bold;

            sup {
              font-size: 20px;
              position: relative;
              top: 4px;
            }
          }

          .group-label {
            font-size: 14px;
          }
        }
      }

      .game-cashout {
        align-items: center;
        background: $codemode-red;
        border-radius: 8px;
        color: white;
        display: flex;
        font-size: 18px;
        font-weight: bold;
        height: 48px;
        justify-content: center;
        margin-top: 24px;
        padding: 0 32px;
      }
    }

    .results-container {
      margin-top: 16px;
      width: 100%;

      .ResultsRow {
        border-bottom: 1px solid #E0E0E0;

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}
