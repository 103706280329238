@import 'src/css/responsive.scss';
@import 'src/css/colors.scss';

@include responsive(mobile) {
  .CashoutRow {
    align-items: center;
    display: flex;
    height: 64px;
    justify-content: space-between;
    padding: 0 16px;

    .player-name {
      font-size: 16px;
    }

    .cashout-details {
      align-items: center;
      display: flex;
      justify-content: flex-end;

      .cashout-input {
        border: 1px solid #808080;
        border-radius: 4px;
        height: 36px;
        flex: 1;
        font-family: 'Roboto Condensed';
        font-size: 16px;
        outline: 0;
        padding: 0 6px;
        text-align: right;
        width: 160px;

        &:focus {
          border: 1px solid $codemode-blue;
        }
      }
    }
  }
}
